import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import BrandItem from "./BrandItem";

const BrandOne = () => {
  return (
    <div
      className='section section-padding-2  '
      style={{ background: "#0660a9	" }}
    >
      <div className='container'>
        <div className='row'>
          <BrandItem />
        </div>
      </div>
    </div>
  );
};

export default BrandOne;
