import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Tilty from "react-tilty";
import { FaPlay } from "react-icons/fa";
import FsLightbox from "fslightbox-react";
import SectionTitle from "../../elements/section-title/SectionTitle";
const BannerThree = () => {
  return (
    <div className='banner banner-style-3' style={{ background: "#0660a9" }}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6'>
            <div className='banner-content'>
              <AnimationOnScroll
                animateIn='slideInUp'
                duration={1}
                animateOnce={true}
                delay={100}
              >
                <span className='subtitle' style={{ color: "#FED132	" }}>
                  Spit on that Thang ($HAWK)
                </span>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn='slideInUp'
                duration={1}
                animateOnce={true}
                delay={200}
              >
                <h1
                  className='title'
                  style={{
                    color: "#0660a9	",
                    WebkitTextStroke: "1px white", // Text stroke

                    fontFamily: "EastBroadway",
                    letterSpacing: "2px",
                  }}
                >
                  HAWK{" "}
                  <span
                    style={{
                      color: "#ed1c23",
                      WebkitTextStroke: "1px white", // Text stroke

                      fontFamily: "EastBroadway",
                      letterSpacing: "2px",
                    }}
                  >
                    {" "}
                    TUAH
                  </span>
                </h1>
                <p
                  style={{
                    color: "#cecece",
                    fontFamily: "EastBroadway",
                    letterSpacing: "2px",
                    textAlign: "justify",
                  }}
                >
                  Inspired by this unapologetic, sexy, and viral moment, we
                  bring you the{" "}
                  <span
                    style={{
                      color: "white",
                      fontFamily: "EastBroadway",
                      letterSpacing: "2px",
                      textAlign: "justify",
                    }}
                  >
                    "Hawk Tuuuah"
                  </span>
                  . This token is not just about cryptocurrency; it's about
                  capturing the essence of raw, unfiltered internet culture and
                  bringing together a community that thrives on humor, boldness,
                  and a touch of the wild. It's a movement born from a viral
                  sensation that exudes boldness and allure.
                  <br></br>
                  <br></br>This token based on Solana aims to capture the
                  essence of internet culture, leveraging the power of memes to
                  create value and excitement.
                </p>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn='slideInUp'
                duration={1}
                animateOnce={true}
                delay={200}
              >
                <div className='btn-group'>
                  <a
                    href='https://raydium.io/swap/?inputMint=14Ak6KegFHLANKALmpjdn1MFW477yvesX8cdzdVEpump&outputMint=sol'
                    className='axil-btn btn-fill-primary btn-large'
                    style={{
                      background: "#0d76bd	",
                      fontFamily: "EastBroadway",
                    }}
                  >
                    Buy Now
                  </a>
                  <Link
                    to='https://www.dextools.io/app/en/solana/pair-explorer/Enh96DNuB9cWsTeJouHePiCjPNoUn8eca1rwqgb7q1R9?t=1720015564355'
                    className='axil-btn btn-fill-primary btn-large'
                    style={{
                      fontFamily: "EastBroadway",
                      color: "white",
                      background: "#ed1c23	",
                    }}
                  >
                    See Chart
                  </Link>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='banner-thumbnail'>
              <div className='large-thumb'>
                <AnimationOnScroll
                  animateIn='slideInUp'
                  duration={1}
                  animateOnce={true}
                  delay={200}
                >
                  <Tilty perspective={2000} reset={false}>
                    {/*<video
                      src='video.mp4'
                      controls={true}
                      style={{ width: "100%", height: "auto" }}
  />*/}
                    <VideoTwo />
                  </Tilty>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className='list-unstyled shape-group-20'>
        <li className='shape shape-1'></li>
        <li className='shape shape-2'></li>
        <div
          className='myimage'
          style={{
            height: "auto",
          }}
        >
          <img src='/solanawhite.png' />
        </div>
        <li className='shape shape-4'>
          <div
            style={{
              width: "100px",
              height: "100px",
              background: "#0d76bd",
              borderRadius: "50%",
            }}
          ></div>
        </li>
        <li className='shape shape-5'></li>
        <li className='shape shape-6'></li>
        <li className='shape shape-7'>
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-31.png"}
            alt='Bubble'
          />
        </li>
        <li className='shape shape-8 marque-images' />
      </ul>
    </div>
  );
};

export default BannerThree;

const VideoTwo = () => {
  const [toggler, setToggler] = useState(false);
  const [lightboxKey, setLightboxKey] = useState(0); // to force lightbox to reinitialize

  useEffect(() => {
    if (toggler) {
      // Force reinitialization to ensure autoplay
      setLightboxKey((prevKey) => prevKey + 1);
    }
  }, [toggler]);

  return (
    <>
      <div className='section section-padding-equal'>
        <div className='container'>
          <div className='about-expert'>
            <div className='thumbnail'>
              <img src='hawk.png' alt='Thumbnail' />
              <div className='popup-video'>
                <button
                  className='play-btn'
                  onClick={() => setToggler(!toggler)}
                >
                  <FaPlay />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FsLightbox
        toggler={toggler}
        sources={[
          <video
            key={lightboxKey}
            controls
            autoPlay
            style={{
              width: " 100%",
              height: "50vh" /* Full viewport height */,
              objectFit: "contain" /* Maintain aspect ratio */,
              display: "flex",
              justifyContent: "center ",
              alignItems: "center",
            }}
          >
            <source src='video.mp4' type='video/mp4' />
            Your browser does not support the video tag.
          </video>,
        ]}
      />
    </>
  );
};
