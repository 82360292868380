import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaPinterestP,
  FaLinkedin,
  FaInstagram,
  FaVimeoV,
  FaDribbble,
  FaBehance,
  FaEnvelopeOpen,
  FaTiktok,
  FaTelegram,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from "../../utils";

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className='container'>
        <div className='footer-top'></div>
        <div className='footer-main'>
          <div className='row'>
            <div className='col-xl-12 col-lg-12'>
              <div className='footer-widget '>
                <div
                  className='footer-newsletter'
                  style={{ textAlign: "center" }}
                >
                  <a
                    style={{
                      fontFamily: "EastBroadway",
                      fontSize: "25px",
                      letterSpacing: "2px",
                      color: "#0660a9	",
                    }}
                    href='mailto:info@hawktuuuah.com'
                  >
                    info@hawktuuuah.com{" "}
                  </a>
                  <div className='footer-social-link'>
                    <ul className='list-unstyled'>
                      <li>
                        <Link to='https://x.com/hawktuuuah'>
                          <FaXTwitter />
                        </Link>
                      </li>
                      <li>
                        <Link to='https://t.me/hawktuuuah'>
                          <FaTelegram />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h2
                    className='title'
                    style={{ fontFamily: "EastBroadway", textAlign: "center" }}
                  >
                    Let's Spit on That Thang
                  </h2>
                  <p
                    style={{
                      color: "#0660a9	",
                      fontSize: "14px",
                      fontFamily: "EastBroadway",
                    }}
                  >
                    Stay tuned for the latest updates, sizzling events, and ways
                    to indulge in the Hawk Tuah sensation!
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-lg-7'>
              <div className='row'></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
