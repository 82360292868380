import React from "react";

const SectionTitle = ({
  subtitle,
  title,
  description,
  textAlignment,
  textColor,
}) => {
  return (
    <div className={`section-heading ${textAlignment}`}>
      <div
        className='subtitle'
        dangerouslySetInnerHTML={{ __html: subtitle }}
        style={{ color: "#0660a9	" }}
      ></div>
      <h2
        className='title'
        dangerouslySetInnerHTML={{ __html: title }}
        style={{
          color: "#ed1c23	",
          fontFamily: "EastBroadway",
          letterSpacing: "2px",
          textAlign: "justify",
          //asdd
        }}
      ></h2>
      <p
        dangerouslySetInnerHTML={{ __html: description }}
        style={{ color: "#0660a9" }}
      ></p>
    </div>
  );
};

export default SectionTitle;
