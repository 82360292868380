import React from "react";
import { Link } from "react-router-dom";
import { FaAngleDown, FaTelegram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Nav = () => {
  return (
    <nav className='mainmenu-nav'>
      <ul className='mainmenu'>
        <li>
          <a href='#'>Home</a>
        </li>
        <li>
          <a href='#tokenomics'>Tokenomics</a>
        </li>{" "}
        <li>
          <a href='#join'>Join $HAWK</a>
        </li>{" "}
        <li>
          <a href='#donate'>Show Love</a>
        </li>{" "}
        <li>
          <a href='#social'>Social Links</a>
        </li>{" "}
      </ul>{" "}
    </nav>
  );
};

export default Nav;
