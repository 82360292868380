import React from "react";
import { Link } from "react-router-dom";

const CtaLayoutOne = () => {
  return (
    <div className='section call-to-action-area'>
      <div className='container'>
        <div className='call-to-action'>
          <div className='section-heading heading-light'>
            <span className='subtitle' style={{ color: "#fed132	" }}>
              Join us on this seductive journey
            </span>
            <h2 className='title' style={{ fontFamily: "EastBroadway" }}>
              Unleash Wild Side <br></br> with{" "}
              <span
                className='title'
                style={{
                  color: "#0660a9	",
                  WebkitTextStroke: "1px white", // Text stroke

                  fontFamily: "EastBroadway",
                  letterSpacing: "2px",
                }}
              >
                HAWK{" "}
                <span
                  style={{
                    color: "#ed1c23",
                    WebkitTextStroke: "1px white", // Text stroke

                    fontFamily: "EastBroadway",
                    letterSpacing: "2px",
                  }}
                >
                  {" "}
                  TUAH
                </span>
              </span>
            </h2>
          </div>{" "}
          <img src='hawk.png' alt='Chat' style={{ margin: "auto" }} />
        </div>
      </div>
    </div>
  );
};

export default CtaLayoutOne;
