import React from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaBehance,
  FaPhone,
  FaFax,
  FaTelegram,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
  return (
    <Offcanvas
      show={offcanvasShow}
      onHide={offcanvasHide}
      placement='end'
      className='header-offcanvasmenu'
    >
      <Offcanvas.Header closeButton></Offcanvas.Header>{" "}
      <img src='logo2.png' style={{ width: "50%", margin: "0 13% " }} />
      <Offcanvas.Body>
        <div className='row '>
          {" "}
          <div className='c '>
            <div className='contact-inner'>
              {" "}
              <ul
                className='main-navigation  list-unstyled'
                style={{ margin: "5% 0" }}
              >
                <li>
                  <a
                    href='#'
                    style={{ fontFamily: "EastBroadway", color: "#0660a9	" }}
                  >
                    Home
                  </a>
                </li>

                <li>
                  <a
                    href='#tokenomics'
                    style={{ fontFamily: "EastBroadway", color: "#0660a9	" }}
                  >
                    Tokenomics
                  </a>
                </li>
                <li>
                  <a
                    href='#join'
                    style={{ fontFamily: "EastBroadway", color: "#0660a9	" }}
                  >
                    Join $HAWK
                  </a>
                </li>
                <li>
                  <a
                    href='#donate'
                    style={{ fontFamily: "EastBroadway", color: "#0660a9	" }}
                  >
                    Show Love
                  </a>
                </li>
              </ul>{" "}
              <div className='footer-social-link'>
                <ul className='list-unstyled'></ul>
              </div>{" "}
              <div className='contact-social-share'>
                <ul className='social-share list-unstyled'>
                  <li>
                    <Link to='https://x.com/hawktuuuah'>
                      <FaXTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link to='https://t.me/hawktuuuah'>
                      <FaTelegram />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMenu;
