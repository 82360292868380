import React from "react";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import Nav from "./Nav";

const MobileMenu = ({ MobileHandler }) => {
  return (
    <div className='mobilemenu-popup' style={{ background: "#0660a9	" }}>
      <div className='mobilemenu-inner' style={{ background: "#0660a9	" }}>
        <div className='mobilemenu-header' style={{ background: "#0660a9	" }}>
          <div className='mobile-nav-logo'>
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img className='light-mode' src='/logo2.png' alt='Site Logo' />
              <img className='dark-mode' src='/logo2.png' alt='Site Logo' />
            </Link>
          </div>
          <button className='mobile-menu-close' onClick={MobileHandler}>
            <FaTimes color='white' />
          </button>
        </div>
        <div className='mobilemenu-body' style={{ background: "#0660a9	" }}>
          <Nav />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
