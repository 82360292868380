import React from "react";

const Data = [
  {
    image: "/b1.webp",
  },
  {
    image: "/b2.webp",
  },
  {
    image: "/b3.webp",
  },
  {
    image: "/b4.png",
  },
];

const BrandItem = () => {
  return (
    <>
      {Data.map((data, index) => (
        <div className='col-lg-3 col-6' key={index}>
          <div className='brand-grid'>
            <img src={process.env.PUBLIC_URL + data.image} alt='Brand' />
          </div>
        </div>
      ))}
    </>
  );
};

export default BrandItem;
