import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import AboutOne from "../component/about/AboutOne";
import BannerOne from "../component/banner/BannerOne";
import BlogOne from "../component/blog/BlogOne";
import BrandOne from "../component/brand/BrandOne";
import CounterUpOne from "../component/counterup/CounterUpOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import PricingOne from "../component/pricing/PricingOne";
import ProjectOne from "../component/project/ProjectOne";
import ServicePropOne from "../component/service/ServicePropOne";
import TestimonialOne from "../component/testimonial/TestimonialOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import BannerThree from "../component/banner/BannerThree";
import HeaderTwo from "../common/header/HeaderTwo";

const DigitalAgency = () => {
  return (
    <>
      <SEO title='Hawk Tuah' />
      <main className='main-wrapper'>
        <HeaderTwo />
        <BannerThree />
        <div id='tokenomics'>
          <div
            className='section section-padding-2  '
            style={{ background: "white	" }}
          >
            <div className='container'>
              <SectionTitle
                subtitle='Designed with passion'
                title='Tokenomics'
                description=' '
                textAlignment='heading-light-left'
                textColor=''
              />
              <div className='row'>
                <ServicePropOne
                  colSize='col-xl-6 col-md-6'
                  serviceStyle=''
                  itemShow='4'
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className='section section-padding-2  '
          style={{
            margin: "0 auto",
            textAlign: "center",
            padding: "0",
          }}
        >
          <h2 style={{ fontFamily: "EastBroadway" }}>Contract Address</h2>
          <aside
            className='contract-address'
            style={{
              color: "#0660a9	",
              textAlign: "center",
              margin: "0 auto",
              textAlign: "center",
              padding: "2% 0",
              fontFamily: "EastBroadway",
            }}
          >
            14Ak6KegFHLANKALmpjdn1MFW477yvesX8cdzdVEpump
          </aside>
        </div>
        <BrandOne />
        <div
          id='donate'
          className='section section-padding-2  '
          style={{
            backgroundColor: "white",
            textAlign: "center",
          }}
        >
          <h2
            className='container'
            style={{ fontFamily: "EastBroadway", color: "#ED1C23" }}
          >
            Support ya HawkTuah girl &nbsp;
            <span
              style={{
                color: "#0660a9",
                fontFamily: "EastBroadway",
                textTransform: "uppercase",
              }}
            >
              Hailey Welch!
            </span>
          </h2>
          <p
            className='container'
            style={{
              color: "#686868	",
              fontFamily: "EastBroadway",
              textAlign: "center",
            }}
          >
            Since Hailey is the one and only true OG of Hawk Tuah, we're opening
            up a community-driven wallet for her to claim. Anyone can donate to
            this wallet, and we will actively push this message on all the
            socials to get her attention.
          </p>
          <aside
            className='contract-address'
            style={{
              color: "#0660a9	",
              textAlign: "center",
              margin: "0 auto",
              textAlign: "center",
              padding: "2% 0",
              fontFamily: "EastBroadway",
            }}
          >
            HAWK1CbJJrttqXCvnwMYY2kjKbsaHzHYZW7GxsmDCwCN
          </aside>
        </div>
        <div id='join' style={{ margin: "0", padding: "0" }}>
          <CtaLayoutOne />
        </div>
        <div id='social'>
          <FooterOne parentClass='' />
        </div>
      </main>
    </>
  );
};

export default DigitalAgency;
